import { EnrollMFA } from 'components/MFA/EnrollMFA';
import { VerifyMFA } from 'components/MFA/VerifyMFA';
import React, { FC, ReactNode } from 'react';

import ConfirmInvitation from './ConfirmInvitation';
import ForgotPassword from './ForgotPassword';
import ForgotPasswordReset from './ForgotPasswordReset';
import SignIn from './SignIn';

export enum SIGN_IN_STATES {
  'SIGN_IN' = 'sign-in',
  'FORGOT_PASSWORD' = 'forgot-password',
  'FORGOT_PASSWORD_RESET' = 'reset-password',
  'CONFIRM_INVITATION' = 'confirm-invitation',
  'COMPLETE_INVITATION' = 'complete-invitation',
  'MFA_ENROLL' = 'mfa-enroll',
  'MFA_VERIFY' = 'mfa-verify',
}

interface LoginUIRendererProps {
  action: string | string[] | undefined;
  loginRouter: (params: Record<string, string> | null) => void;
  setIsLoading: (args: boolean) => void;
  setHeader: (content: ReactNode) => void;
}

export const LoginUIRenderer: FC<LoginUIRendererProps> = ({
  action,
  loginRouter,
  setHeader,
}) => {
  switch (action) {
    case SIGN_IN_STATES.FORGOT_PASSWORD:
      return <ForgotPassword loginRouter={loginRouter} />;

    case SIGN_IN_STATES.FORGOT_PASSWORD_RESET:
      return <ForgotPasswordReset loginRouter={loginRouter} />;

    case SIGN_IN_STATES.CONFIRM_INVITATION:
      return (
        <ConfirmInvitation loginRouter={loginRouter} setHeader={setHeader} />
      );

    case SIGN_IN_STATES.MFA_ENROLL:
      return <EnrollMFA />;

    case SIGN_IN_STATES.MFA_VERIFY:
      return <VerifyMFA />;

    default:
      return <SignIn loginRouter={loginRouter} />;
  }
};
