import { InputWithLabel, SubmitHandler } from '@visto-tech/forms';
import {
  FeatureBlockedType,
  FeaturedBlockedNotice,
} from 'components/Stripe/DisabledAccess';
import User from 'dataLayer/User';
import { Button } from 'elements/Button';
import ErrorDisplay from 'elements/ErrorDisplay/ErrorDisplay';
import Loading from 'elements/Loading';
import Text from 'elements/Text';
import { MfaLevel, UpdateUserAction } from 'generated/graphql';
import { useAccountSubscription } from 'hooks/useAccountSubscription';
import { useActiveUser } from 'hooks/useActiveUser';
import { useAsyncEffect } from 'hooks/useAsyncEffect';
import { useFormManager } from 'hooks/useFormManager';
import { observer } from 'mobx-react-lite';
import { useRouter } from 'next/router';
import { FC, useState } from 'react';
import { Lock } from 'react-feather';
import { toast } from 'react-hot-toast';
import logger from 'utils/logger';
import { supabase } from 'utils/supabase';
import * as yup from 'yup';

const ENROLL_MFA_FORM_DATA = {
  verify: '',
};

// eslint-disable-next-line sonarjs/cognitive-complexity
export const EnrollMFA: FC = observer(() => {
  const router = useRouter();
  const user = useActiveUser();
  const { subscription, isFetchingSub } = useAccountSubscription();

  const [isIntro, setIsIntro] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isUnenrolling, setIsUnenrolling] = useState<boolean>(false);
  const [factorId, setFactorId] = useState<string>('');
  const [qr, setQR] = useState<string>('');

  const formManager = useFormManager({
    formData: ENROLL_MFA_FORM_DATA,
    validationSchema: yup.object({
      verify: yup.string().required('Enter a code'),
    }),
  });

  const refresh = async (status: { isMounted: boolean }) => {
    setIsLoading(true);

    try {
      const mfaLevel = await supabase.auth.mfa.getAuthenticatorAssuranceLevel();

      const isAlreadyEnrolled = mfaLevel.data?.currentLevel === MfaLevel.Aal2;

      if (isAlreadyEnrolled) {
        const factors = await supabase.auth.mfa.listFactors();

        const factor = factors.data?.totp.find(
          (factor) => factor.status === 'verified'
        );

        setFactorId(factor?.id ? factor.id : '');
        setIsLoading(false);
        return null;
      }
    } catch (e) {
      logger.error(e);
      toast.error('Error fetching current enrollment');
      return null;
    }

    if (isIntro) {
      setIsLoading(false);
      return null;
    }

    try {
      const { data, error } = await supabase.auth.mfa.enroll({
        factorType: 'totp',
      });

      if (error) {
        logger.error(error);
        toast.error(`Error requesting MFA enrollment`);
        formManager.addCustomError(error.message);
        setIsLoading(false);
        return;
      }

      if (status.isMounted) {
        setFactorId(data?.id as string);
        setQR(data?.totp.qr_code as string);
        setIsLoading(false);
      }
    } catch (e) {
      logger.error(e);
      toast.error(`Sorry, something went wrong`);
      formManager.addCustomError((e as any).message);
      setIsLoading(false);
    }
  };

  useAsyncEffect(refresh, [isIntro]);

  const onEnroll: SubmitHandler<typeof ENROLL_MFA_FORM_DATA> = async (
    _,
    formData
  ) => {
    const enrollMfa = await User.updateUser({
      action: UpdateUserAction.MfaEnroll,
      data: {
        mfaFactorId: factorId,
        mfaTopt: formData.verify,
      },
    });

    if (!enrollMfa) {
      toast.error('Failed to set up MFA');
      return null;
    }

    toast.success('MFA setup successful!');
    router.push('/login?action=mfa-verify', undefined, { shallow: true });
  };

  const onUnenroll = async () => {
    if (!factorId) {
      toast.error('Missing factor ID');
      return null;
    }

    setIsUnenrolling(true);

    if (
      confirm(
        'Are you sure you want to disable your multi-factor authentication?'
      )
    ) {
      try {
        const unenrollMfa = await User.updateUser({
          action: UpdateUserAction.MfaUnenroll,
          data: {
            mfaFactorId: factorId,
          },
        });

        if (!unenrollMfa) {
          toast.error('Failed to disable MFA');
          return null;
        }

        await supabase.auth.refreshSession();

        await refresh({ isMounted: true });

        toast.success('MFA disabled');

        setIsIntro(true);
        setIsUnenrolling(false);
      } catch (e) {
        logger.error(e);
        toast.error(`Sorry, something went wrong`);
        setIsUnenrolling(false);
      }
    }
  };

  if (isLoading || isFetchingSub) {
    return <Loading.Default />;
  }

  let Content = (
    <>
      <Text.Paragraph className="text-center fs21 font-bold mb-2">
        Multi-factor Authentication
      </Text.Paragraph>
      <Text.Paragraph className="text-center mb-2">
        Visto supports multi-factor authentication (MFA) which is also know as
        two-factor authentication (2FA).
      </Text.Paragraph>
      <Text.Paragraph className="text-center mb-9">
        Multi-factor authentication is the process of adding an additional step
        to your sign in process for Visto. In this case, we use a TOTP
        (time-based one-time password) additional verification flow.
      </Text.Paragraph>
      <Button
        className="mx-auto mt-9"
        size="large"
        onClick={() => setIsIntro(false)}
      >
        Continue to Setup MFA
      </Button>
    </>
  );

  if (!isIntro && qr) {
    Content = (
      <>
        <Text.Paragraph className="text-center fs21 font-bold">
          Set up your MFA
        </Text.Paragraph>
        <Text.Paragraph className="text-center mb-6">
          Follow the instructions below to set up your multi-factor
          authentication.
        </Text.Paragraph>
        <div className="grid grid-cols-2 gap-6">
          <div className="bg-gray-100 rounded-md p-6">
            <Text.Paragraph className="text-center font-bold mb-1 fs14">
              Step 1
            </Text.Paragraph>
            <Text.Paragraph className="text-center">
              Open up your authenticator app or download one to your smartphone.
            </Text.Paragraph>
            <Text.Paragraph className="text-center fs12 italic mt-1">
              If you do not have an authenticator app, we recommend the{' '}
              <a
                className="underline"
                target="_blank"
                href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en_CA&gl=US&pli=1"
                rel="noreferrer"
              >
                Google Authenticator
              </a>
              .
            </Text.Paragraph>
          </div>
          <div className="bg-gray-100 rounded-md p-6">
            <Text.Paragraph className="text-center font-bold mb-1 fs14">
              Step 2
            </Text.Paragraph>
            <Text.Paragraph className="text-center">
              Using your authenticator app, follow the steps to add a new
              property or website.
            </Text.Paragraph>
          </div>
          <div className="bg-gray-100 rounded-md p-6">
            <Text.Paragraph className="text-center font-bold mb-1 fs14">
              Step 3
            </Text.Paragraph>
            <Text.Paragraph className="text-center">
              Using your authenticator app, scan the QR image below and follow
              any on-screen instructions in your authenticator app.
            </Text.Paragraph>
          </div>
          <div className="bg-gray-100 rounded-md p-6">
            <Text.Paragraph className="text-center font-bold mb-1 fs14">
              Step 4
            </Text.Paragraph>
            <Text.Paragraph className="text-center">
              Enter the TOTP (time-based one-time password) that is displayed in
              your authenticator app below and click "Submit" before it expires.
            </Text.Paragraph>
          </div>
        </div>
        <img alt="QR code" src={qr} className="mx-auto text-center mt-4" />
        <form
          id={`form-mfa`}
          onSubmit={formManager.createSubmitHandler(onEnroll)}
          className="space-y-6 max-w-sm mx-auto"
        >
          <ErrorDisplay formManager={formManager} />
          <div className="flex flex-col mt-6 justify-center space-y-6">
            <InputWithLabel.WithFormManager
              id="verify"
              name="verify"
              inputProps={{
                ref: (input) => input && input.focus(),
                autoComplete: 'off',
              }}
              formManager={formManager}
            >
              TOTP (time-based one-time password)
            </InputWithLabel.WithFormManager>
            <Button
              className="text-center mx-auto"
              type="submit"
              isLoading={formManager.isSubmitting}
              disabled={formManager.isSubmitting}
              onClick={() => formManager.clearErrors()}
              size="large"
            >
              Submit
            </Button>
          </div>
        </form>
      </>
    );
  }

  if (user?.mfaActive) {
    Content = (
      <>
        <Text.Paragraph className="text-center fs21 font-bold mb-3">
          Manage Active MFA
        </Text.Paragraph>
        <Text.Paragraph className="text-center mb-1">
          Your MFA (multi-factor authentication) is currently{' '}
          <strong className="text-green-500">active</strong>.
        </Text.Paragraph>
        <Text.Paragraph className="text-center mb-6">
          You can disable it by click disable below.
        </Text.Paragraph>
        <Button
          isLoading={isUnenrolling}
          disabled={isUnenrolling}
          className="mx-auto"
          size="large"
          onClick={async () => await onUnenroll()}
        >
          Disable MFA
        </Button>
      </>
    );
  }

  const isBlocked =
    !user?.mfaActive &&
    !subscription?.access?.mfa?.allowed &&
    user?.isType('LAWYER');

  if (isBlocked) {
    Content = <FeaturedBlockedNotice type={FeatureBlockedType.MFA} />;
  }

  return (
    <>
      {!isBlocked && <Lock size={44} className="text-gray-400 mx-auto mb-3" />}
      {Content}
    </>
  );
});
