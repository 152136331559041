import { InputWithLabel, SubmitHandler } from '@visto-tech/forms';
import axios from 'axios';
import ErrorDisplay from 'elements/ErrorDisplay/ErrorDisplay';
import Loading from 'elements/Loading';
import { useAsyncEffect } from 'hooks/useAsyncEffect';
import { useFormManager } from 'hooks/useFormManager';
import { useRouter } from 'next/dist/client/router';
import React, { FC, useEffect, useState } from 'react';
import { InvitationAction } from 'static/api';
import logger from 'utils/logger';

import Invitation from '../../dataLayer/Invitation';
import Button from '../../elements/Button/Button';
import { Text } from '../../elements/Text';
import {
  COMPLETE_INVITATION_FORM_VALIDATION,
  COMPLETE_INVITATION_INITIAL_FORM_DATA,
} from './constants';
import { LoginComponentProps } from './LoginComponentProps';

const ConfirmInvitation: FC<LoginComponentProps> = ({ setHeader }) => {
  const router = useRouter();

  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [checkedTerms, setCheckedTerms] = useState(false);
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [invitation, setInvitation] = useState<null | undefined | Invitation>(
    null
  );
  const slug: string | string[] | undefined = router.query.invitation;

  const formManager = useFormManager({
    formData: COMPLETE_INVITATION_INITIAL_FORM_DATA,
    validationSchema: COMPLETE_INVITATION_FORM_VALIDATION,
  });

  const handleConfirmAccount: SubmitHandler<
    typeof COMPLETE_INVITATION_INITIAL_FORM_DATA
  > = async (_, data) => {
    setIsLoading(true);

    if (data.new_password !== data.confirm_new_password) {
      formManager.addError('Your passwords do not match');
      setIsLoading(false);
      return;
    }

    let response;

    try {
      response = await axios.post('/api/invitation', {
        action: InvitationAction.CONFIRM,
        slug,
        email: invitation?.email,
        password: data.new_password,
        passwordConfirm: data.confirm_new_password,
      });

      setIsLoading(false);
    } catch (_e) {
      formManager.addError((_e as any).response?.data?.message);
      logger.warn('Unknown object thrown: ', _e);
      setIsLoading(false);
      return null;
    }

    if (response.data.error) {
      formManager.addError(response.data.error);
      setIsLoading(false);
      return null;
    }

    setSuccess(true);
  };

  const getInvitation = async () => {
    try {
      const { data } = await axios.post('/api/invitation', {
        action: InvitationAction.GET,
        slug,
      });

      setInvitation(data);
    } catch (_e) {
      logger.warn('Unknown object thrown: ', _e);
      return null;
    }
  };

  useAsyncEffect(getInvitation, []);

  useEffect(() => {
    setHeader &&
      setHeader(
        <Text.Paragraph className="max-w-lg w-full text-white text-center mx-auto font-bold bg-gradient-to-r from-[#2897f2] to-blue-600 rounded-lg border p-3 break-words mb-3">
          <span>{invitation?.email}</span>
        </Text.Paragraph>
      );
  }, [invitation]);

  if (!invitation) {
    return <Loading.Default size={2} />;
  }

  if (invitation.status === 'COMPLETED') {
    return (
      <InvitationCompleted heading="Invitation Completed">
        Sorry, this invitation has already been completed. Please sign in. If
        you think this is an error, please contact the Visto team.
      </InvitationCompleted>
    );
  }

  if (invitation.isExpired) {
    return (
      <InvitationCompleted heading="Invitation Expired">
        Sorry, this invitation has expired. Please reach out to our team using
        the chat widget below if you are having issues with your invitation
        link.
      </InvitationCompleted>
    );
  }

  return (
    <>
      {!success ? (
        <div>
          <ErrorDisplay formManager={formManager} />

          <Text.Heading variant="form-heading" className="text-center mb-1">
            Create your password
          </Text.Heading>
          <Text.Paragraph className="text-center mb-3 mx-auto max-w-sm">
            To <strong className="underline">complete your sign up</strong>,
            please create your password below
          </Text.Paragraph>
          <form
            className="max-w-2xl"
            onSubmit={formManager.createSubmitHandler(handleConfirmAccount)}
          >
            <div className="flex justify-end mb-2">
              <button
                type="button"
                className="fs12 underline opacity-50 hover:opacity-100"
                onClick={() => setIsShowPassword(!isShowPassword)}
              >
                {isShowPassword ? 'Hide' : 'Show'} passwords
              </button>
            </div>
            <InputWithLabel.WithFormManager
              id="new_password-id"
              name="new_password"
              formManager={formManager}
              inputProps={{
                type: isShowPassword ? 'text' : 'password',
                autoComplete: 'new-password',
              }}
              formFieldSetProps={{
                className: 'mb-4',
              }}
            >
              Password
            </InputWithLabel.WithFormManager>
            <InputWithLabel.WithFormManager
              id="confirm_new_password-id"
              name="confirm_new_password"
              formManager={formManager}
              inputProps={{
                type: isShowPassword ? 'text' : 'password',
                autoComplete: 'new-password',
              }}
            >
              Confirm Password
            </InputWithLabel.WithFormManager>
            <div className="flex items-center mt-5">
              <input
                type="checkbox"
                id="acceptTerms"
                name="acceptTerms"
                onChange={() => setCheckedTerms(!checkedTerms)}
                className="rounded-sm border-2 border-black mr-4 w-5 h-5"
              />
              <label className="fs14" htmlFor="acceptTerms">
                By signing up, I accept the{' '}
                <a
                  target="_blank"
                  href="/legal/terms-of-service"
                  className="underline"
                >
                  Terms of Service
                </a>
                ,{' '}
                <a
                  target="_blank"
                  href="/legal/privacy-policy"
                  className="underline"
                >
                  Privacy Policy
                </a>{' '}
                and{' '}
                <a
                  target="_blank"
                  href="/legal/cookie-policy"
                  className="underline"
                >
                  Cookies Policy
                </a>
                .
              </label>
            </div>
            <Button
              type="submit"
              disabled={isLoading || !checkedTerms}
              isLoading={isLoading}
              className={`mt-6 mx-auto ${
                !checkedTerms && 'pointer-events-none opacity-40'
              }`}
              size="large"
              onClick={() => formManager.clearErrors()}
            >
              Sign Up
            </Button>
            {!checkedTerms && (
              <Text.Paragraph className="text-center fs12 text-red-600 font-semibold mt-3">
                Accept terms above to continue
              </Text.Paragraph>
            )}
          </form>
        </div>
      ) : (
        <div>
          <div className="flex justify-center mb-3">
            <img className="w-28" src="/finger_pointing_dark.png" alt="" />
          </div>
          <Text.Heading
            variant="form-heading"
            className="text-center mb-2 fs24"
          >
            Hold up! Check your email.
          </Text.Heading>
          <Text.Paragraph className="text-center mb-1">
            You're almost done!
          </Text.Paragraph>
          <Text.Paragraph className="text-center">
            Please check your email for an email from{' '}
            <strong>hello@visto.ai</strong> to verify your email address.
          </Text.Paragraph>
          <Text.Paragraph className="text-center mt-4 fs14 text-gray-400 italic">
            Please note that emails can take up to 5 minutes to be received in
            to your inbox.
          </Text.Paragraph>
        </div>
      )}
    </>
  );
};

export default ConfirmInvitation;

const InvitationCompleted: FC<{ heading: string }> = ({
  heading,
  children,
}) => {
  return (
    <div>
      <Text.Heading className="text-center mb-2" variant="form-heading">
        {heading}
      </Text.Heading>
      <Text.Paragraph className="text-center">{children}</Text.Paragraph>
      <Button className="mx-auto mt-6" link="/login">
        Sign In
      </Button>
    </div>
  );
};
