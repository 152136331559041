import { SubmitHandler } from '@visto-tech/forms';
import { InputWithLabel } from '@visto-tech/forms';
import User from 'dataLayer/User';
import ErrorDisplay from 'elements/ErrorDisplay/ErrorDisplay';
import { useRouter } from 'next/router';
import React, { FC, useState } from 'react';
import toast from 'react-hot-toast';
import { redirectUser } from 'utils/helpers';

import Button from '../../elements/Button/Button';
import { Text } from '../../elements/Text';
import { useFormManager } from '../../hooks/useFormManager';
import {
  FORGOT_PASSWORD_SUBMIT_FORM_VALIDATION,
  FORGOT_PASSWORD_SUBMIT_INITIAL_FORM_DATA,
} from './constants';
import { LoginComponentProps } from './LoginComponentProps';

const ForgotPasswordReset: FC<LoginComponentProps> = ({ loginRouter }) => {
  const router = useRouter();

  const [isLoading, setIsLoading] = useState(false);
  const [isShowPassword, setIsShowPassword] = useState(false);

  const formManager = useFormManager({
    formData: FORGOT_PASSWORD_SUBMIT_INITIAL_FORM_DATA,
    validationSchema: FORGOT_PASSWORD_SUBMIT_FORM_VALIDATION,
  });

  const handleConfirmation: SubmitHandler<
    typeof FORGOT_PASSWORD_SUBMIT_INITIAL_FORM_DATA
  > = async (_, { password, passwordConfirm }) => {
    setIsLoading(true);

    const resetPassword = await User.resetPassword({
      newPassword: password,
      newPasswordConfirm: passwordConfirm,
      token: `${router.query?.token}`,
    });

    if (!resetPassword?.success) {
      formManager.addError(resetPassword?.message);
      setIsLoading(false);
      return null;
    }

    toast.success('Password reset successful!');
    toast.success('Please sign in with your new password');

    redirectUser('/login');

    setIsLoading(false);
  };

  const onSubmitHandler = formManager.createSubmitHandler(handleConfirmation);

  return (
    <>
      <ErrorDisplay formManager={formManager} />
      <form
        className="max-w-2xl"
        onSubmit={(e) => {
          e.preventDefault();
          formManager.clearErrors();
          return onSubmitHandler(e);
        }}
      >
        <Text.Heading className="text-center mb1" variant="form-heading">
          Create New Password
        </Text.Heading>
        <Text.Paragraph className="text-center mb-4 mx-auto max-w-sm">
          Please enter your new password below.
        </Text.Paragraph>
        <div className="flex justify-end mb-2">
          <button
            type="button"
            className="fs12 underline opacity-50 hover:opacity-100"
            onClick={() => setIsShowPassword(!isShowPassword)}
          >
            {isShowPassword ? 'Hide' : 'Show'} passwords
          </button>
        </div>
        <InputWithLabel.WithFormManager
          formFieldSetProps={{
            className: 'mt-4',
          }}
          id="password-id"
          name="password"
          inputProps={{
            type: isShowPassword ? 'text' : 'password',
          }}
          formManager={formManager}
        >
          New password
        </InputWithLabel.WithFormManager>
        <InputWithLabel.WithFormManager
          formFieldSetProps={{
            className: 'mt-4',
          }}
          id="passwordConfirm-id"
          name="passwordConfirm"
          inputProps={{
            type: isShowPassword ? 'text' : 'password',
          }}
          formManager={formManager}
        >
          Confirm new password
        </InputWithLabel.WithFormManager>
        <div className="flex flex-col mt-6 justify-center">
          <Button
            type="submit"
            disabled={isLoading}
            isLoading={isLoading}
            className="mx-auto"
            size="large"
          >
            Reset Password
          </Button>
          <button
            className="text-center underline mt-3"
            onClick={() => loginRouter(null)}
          >
            Cancel
          </button>
        </div>
      </form>
    </>
  );
};

export default ForgotPasswordReset;
