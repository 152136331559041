import { LoginUIRenderer } from 'components/Login/LoginUIRenderer';
import { GoogleTranslateSelect } from 'components/Translate/GoogleTranslate';
import Cookies from 'cookies';
import User from 'dataLayer/User';
import Loading from 'elements/Loading';
import { MessageDisplay } from 'elements/MessageDisplay/MessageDisplay';
import Text from 'elements/Text';
import router, { useRouter } from 'next/router';
import React, { ReactNode, useEffect, useState } from 'react';
import { SUPABASE_COOKIE_NAME } from 'utils/supabase';

import { useLayout } from '../hooks/useLayout';

const loginRouter = (params: Record<string, string> | null) => {
  router.push(
    {
      pathname: '/login',
      query: params ? params : null,
    },
    undefined,
    { shallow: true }
  );
};

const LoginPage = () => {
  useLayout({ layout: 'Base', title: 'Login' });

  const router = useRouter();
  const action = router.query.action;
  const isRedirecting = router.query.redirectTo;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [header, setHeader] = useState<ReactNode | null>(null);

  useEffect(() => {
    setHeader(null);
  }, [action]);

  useEffect(() => {
    localStorage.removeItem('visto-impersonate');
  }, []);

  return (
    <div
      className={`mt-24 mx-4 max-w-lg w-full ${header ? 'flex flex-col' : ''}`}
    >
      {header}
      <div className="bg-white rounded-lg border border-visto-gray md:mx-auto p-6 md:p-10 box-border text-visto justify-items-center w-full">
        {!isLoading ? (
          <>
            {isRedirecting && (
              <MessageDisplay
                size="mini"
                hideImage
                variation="warning"
                className="mb-4"
              >
                <Text.Paragraph className="fs12 text-center font-semibold">
                  Your session has timed out. Sign in again to access the page.
                </Text.Paragraph>
              </MessageDisplay>
            )}
            <LoginUIRenderer
              action={action}
              loginRouter={loginRouter}
              setIsLoading={setIsLoading}
              setHeader={setHeader}
            />
          </>
        ) : (
          <Loading.Default size={2} />
        )}
        <div className="flex justify-center mt-4">
          <GoogleTranslateSelect />
        </div>
      </div>
    </div>
  );
};

export async function getServerSideProps(context: any) {
  const cookies = Cookies(context?.req as any, context?.res as any);
  const token = cookies.get(SUPABASE_COOKIE_NAME);

  if (token) {
    const user = await User.getUserIncludes({ token });

    if (user && user.id) {
      return {
        redirect: {
          permanent: false,
          destination: '/',
        },
        props: {},
      };
    }
  }
  return {
    props: {},
  };
}

export default LoginPage;
