import * as yup from 'yup';

const DATE_REGEX_VALIDATION =
  /(?:19|20)[0-9]{2}-(?:(?:0[1-9]|1[0-2])-(?:0[1-9]|1[0-9]|2[0-9])|(?:(?!02)(?:0[1-9]|1[0-2])-(?:30))|(?:(?:0[13578]|1[02])-31))/;

export const COMPLETE_INVITATION_INITIAL_FORM_DATA = {
  new_password: '',
  confirm_new_password: '',
};

export const COMPLETE_INVITATION_FORM_VALIDATION = yup.object({
  new_password: yup.string().required('Please enter a new password'),
  confirm_new_password: yup
    .string()
    .required('Please confirm your new password'),
});

export const FORGOT_PASSWORD_INITIAL_FORM_DATA = {
  email: '',
};

export const FORGOT_PASSWORD_FORM_VALIDATION = yup.object({
  email: yup
    .string()
    .required('You must enter your email to re-set your password'),
});

export const FORGOT_PASSWORD_SUBMIT_INITIAL_FORM_DATA = {
  password: '',
  passwordConfirm: '',
};

export const FORGOT_PASSWORD_SUBMIT_FORM_VALIDATION = yup.object({
  password: yup.string().required('You must enter your new password'),
  passwordConfirm: yup
    .string()
    .required('You must enter a new password confirm'),
});

export const SIGN_IN_INITIAL_FORM_DATA = {
  email: '',
  password: '',
  authCode: '',
};

export const SIGN_IN_FORM_VALIDATION = yup.object({
  email: yup.string().required('You must enter your email to login'),
  password: yup.string().required('You must enter your password to login'),
});

export const CREATE_TALENT_CANDIDATE_FORM_VALIDATION = yup.object({
  email: yup.string().required('You must enter an email'),
  role: yup.string().required('You must select a Role'),
});

export const CREATE_TALENT_EMPLOYEE_FORM_VALIDATION = yup.object({
  email: yup.string().required('You must enter an email'),
});

export const APPROVE_APPLICATION_FORM_DATA = {
  approvedOn: '',
  expiresOn: '',
};

export const APPROVE_APPLICATION_FORM_VALIDATION = yup.object({
  approvedOn: yup
    .string()
    .required('Please select a approved on date')
    .matches(
      DATE_REGEX_VALIDATION,
      'Please make sure your date is formatted like: YYYY-MM-DD'
    ),
  expiresOn: yup
    .string()
    .required('Please select a expires on date')
    .matches(
      DATE_REGEX_VALIDATION,
      'Please make sure your date is formatted like: YYYY-MM-DD'
    ),
});

export const TALENT_EMPLOYEE_PERMIT_FORM_DATA = {
  approvedOn: '',
  expiresOn: '',
};

export const TALENT_EMPLOYEE_PERMIT_FORM_VALIDATION = yup.object({
  approvedOn: yup.string().required('Please select a approved on date'),
  expiresOn: yup.string().required('Please select a expires on date'),
});
