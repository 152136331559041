import { InputWithLabel, SubmitHandler } from '@visto-tech/forms';
import User from 'dataLayer/User';
import { Button } from 'elements/Button';
import ErrorDisplay from 'elements/ErrorDisplay/ErrorDisplay';
import Text from 'elements/Text';
import { useFormManager } from 'hooks/useFormManager';
import { observer } from 'mobx-react-lite';
import { useRouter } from 'next/router';
import { FC } from 'react';
import { Lock } from 'react-feather';
import { toast } from 'react-hot-toast';
import logger from 'utils/logger';
import { supabase } from 'utils/supabase';
import * as yup from 'yup';

const VERIFY_MFA_FORM_DATA = {
  verify: '',
};

export const VerifyMFA: FC = observer(() => {
  const formManager = useFormManager({
    formData: VERIFY_MFA_FORM_DATA,
    validationSchema: yup.object({
      verify: yup.string().required('Enter your OTP'),
    }),
  });

  const router = useRouter();

  const onFormSubmit: SubmitHandler<typeof VERIFY_MFA_FORM_DATA> = async (
    _e,
    formData
  ) => {
    const { data, error } = await supabase.auth.mfa.listFactors();

    if (error) {
      logger.error(error);
      formManager.addCustomError(error.message);
      return null;
    }

    const totpFactor = data?.totp[0];

    if (!totpFactor) {
      formManager.addCustomError('No TOTP factors found!');
      return;
    }

    const factorId = totpFactor.id;

    const challenge = await supabase.auth.mfa.challenge({ factorId });

    if (challenge.error) {
      formManager.addCustomError(challenge.error.message);
      return null;
    }

    const challengeId = challenge.data.id;

    try {
      const verify = await supabase.auth.mfa.verify({
        factorId,
        challengeId,
        code: formData.verify,
      });

      if (verify.error) {
        formManager.addCustomError(verify.error.message);
        return null;
      }

      toast.success('Verified');

      const session = {
        access_token: verify.data.access_token,
        refresh_token: verify.data.refresh_token,
      };

      await fetch('/api/auth', {
        method: 'POST',
        headers: new Headers({ 'Content-Type': 'application/json' }),
        credentials: 'same-origin',
        body: JSON.stringify({ event: 'SIGN_IN', session }),
      });

      const { data } = await supabase.auth.refreshSession();

      const user = await User.getActiveUser({
        token: data.session?.access_token,
      });

      router.push(user?.isType('SUPER_ADMIN') ? '/admin' : '');
      router.reload();
    } catch (e) {
      logger.error(e);
      formManager.addCustomError((e as any).message);
      return null;
    }
  };

  return (
    <>
      <Lock size={44} className="text-gray-400 mx-auto mb-3" />
      <Text.Paragraph className="text-center fs21 font-bold">
        Enter your TOTP
      </Text.Paragraph>
      <Text.Paragraph className="text-center mb-6">
        Open up your authenticator app on your phone and enter the TOTP
        (time-based one-time password) before it expires.
      </Text.Paragraph>
      <form
        id={`form-mfa`}
        onSubmit={formManager.createSubmitHandler(onFormSubmit)}
        className="space-y-6 max-w-sm mx-auto"
      >
        <ErrorDisplay formManager={formManager} />
        <div className="flex flex-col mt-6 justify-center space-y-6">
          <div>
            <InputWithLabel.WithFormManager
              id="verify"
              name="verify"
              inputProps={{
                ref: (input) => input && input.focus(),
                autoComplete: 'off',
              }}
              formManager={formManager}
            >
              TOTP (time-based one-time password)
            </InputWithLabel.WithFormManager>
          </div>
          <Button
            className="text-center mx-auto"
            type="submit"
            isLoading={formManager.isSubmitting}
            disabled={formManager.isSubmitting}
            onClick={() => formManager.clearErrors()}
            size="large"
          >
            Submit
          </Button>
        </div>
      </form>
    </>
  );
});
