import { SubmitHandler } from '@visto-tech/forms';
import { InputWithLabel } from '@visto-tech/forms';
import User from 'dataLayer/User';
import ErrorDisplay from 'elements/ErrorDisplay/ErrorDisplay';
import React, { FC, useState } from 'react';
import toast from 'react-hot-toast';

import Button from '../../elements/Button/Button';
import { Text } from '../../elements/Text';
import { useFormManager } from '../../hooks/useFormManager';
import {
  FORGOT_PASSWORD_FORM_VALIDATION,
  FORGOT_PASSWORD_INITIAL_FORM_DATA,
} from './constants';
import { LoginComponentProps } from './LoginComponentProps';

const FogotPassword: FC<LoginComponentProps> = ({ loginRouter }) => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const formManager = useFormManager({
    formData: FORGOT_PASSWORD_INITIAL_FORM_DATA,
    validationSchema: FORGOT_PASSWORD_FORM_VALIDATION,
  });

  const handleForgotPassword: SubmitHandler<
    typeof FORGOT_PASSWORD_INITIAL_FORM_DATA
  > = async (_, { email }) => {
    setIsLoading(true);

    const resetPassword = await User.requestPasswordReset(email);

    if (!resetPassword) {
      toast.error('Failed to reset password');
      return null;
    }

    toast.success(
      'If a user with this email exists, a password reset link has been sent to their email'
    );

    setIsSubmitted(true);
  };
  return (
    <>
      {!isSubmitted ? (
        <>
          <ErrorDisplay formManager={formManager} />
          <form
            className="max-w-2xl"
            onSubmit={formManager.createSubmitHandler(handleForgotPassword)}
          >
            <Text.Heading className="text-center mb-1" variant="form-heading">
              Forgot Password
            </Text.Heading>
            <Text.Paragraph className="text-center mb-4 mx-auto max-w-sm">
              Enter your email you signed up for Visto with to reset your
              password. Once submitted, please check your email.
            </Text.Paragraph>
            <InputWithLabel.WithFormManager
              id="email-id"
              name="email"
              inputProps={{
                type: 'email',
              }}
              formManager={formManager}
              placeholder="Your email"
            >
              Email Address
            </InputWithLabel.WithFormManager>
            <div className="flex flex-col space-y-6 mt-6 justify-center">
              <Button
                type="submit"
                disabled={isLoading}
                isLoading={isLoading}
                className="mx-auto"
                size="large"
              >
                Submit
              </Button>
              <button
                className="text-center underline mt-2"
                onClick={() => loginRouter(null)}
              >
                Cancel
              </button>
            </div>
          </form>
        </>
      ) : (
        <div>
          <img
            src="/finger_pointing_dark.png"
            alt="Point"
            className="w-20 mb-4 mx-auto"
          />
          <Text.Heading variant="none" className="text-center mb-2 fs24">
            Password Reset Email Sent
          </Text.Heading>
          <Text.Paragraph className="text-center">
            Please check your email for the next steps.
          </Text.Paragraph>
        </div>
      )}
    </>
  );
};

export default FogotPassword;
